import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

const DocumentViewer: React.FC = () => {
  const [content, setContent] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetch('/api/document')
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        if (typeof data.content !== 'string') throw new Error('Received content is not a string');
        setContent(data.content);
      })
      .catch(err => {
        console.error('Error fetching document:', err);
        setError(err.message);
      });
  }, []);

  if (error) return <div className="text-red-500 p-4">Error: {error}</div>;

  return (
    <div className="max-w-4xl px-4 py-8">
      <div className="prose prose-lg prose-blue mx-auto font-signifier" style={{ fontFamily: 'Signifier, serif' }}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            h1: ({node, ...props}) => <h1 className="text-4xl font-bold mb-6 font-sans" {...props} />,
            h2: ({node, ...props}) => <h2 className="text-3xl font-semibold mt-8 mb-4 font-sans" {...props} />,
            h3: ({node, ...props}) => <h3 className="text-2xl font-medium mt-6 mb-3 font-sans" {...props} />,
            p: ({node, ...props}) => <p className="mb-4 leading-relaxed" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-4" {...props} />,
            ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-4" {...props} />,
            li: ({node, ...props}) => <li className="mb-2" {...props} />,
            blockquote: ({node, ...props}) => (
              <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4" {...props} />
            ),
            code({node, inline, className, children, ...props}: {
              node?: any;
              inline?: boolean;
              className?: string;
              children?: React.ReactNode;
              [key: string]: any;
            } & React.HTMLAttributes<HTMLElement>) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  style={atomDark as any}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            }
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default DocumentViewer;