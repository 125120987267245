import React, { createContext, useState, useContext, ReactNode } from 'react';

type Message = { role: 'user' | 'ai'; content: string };
type ChatHistories = { [source: string]: Message[] };

interface ChatContextType {
  chatHistories: ChatHistories;
  addMessage: (source: string, message: Message) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [chatHistories, setChatHistories] = useState<ChatHistories>({});

  const addMessage = (source: string, message: Message) => {
    setChatHistories(prev => ({
      ...prev,
      [source]: [...(prev[source] || []), message],
    }));
  };

  return (
    <ChatContext.Provider value={{ chatHistories, addMessage }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};