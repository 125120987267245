import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import DocumentViewer from './components/DocumentViewer';
import Chat from './components/Chat';
import PasswordPopup from './components/PasswordPopup';
import { ChatProvider } from './contexts/ChatContext';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const authenticatedCookie = document.cookie.split('; ').find(row => row.startsWith('authenticated='));
    setIsAuthenticated(!!authenticatedCookie);
  }, []);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
  };

  if (!isAuthenticated) {
    return <PasswordPopup onAuthenticate={handleAuthentication} />;
  }

  return (
    <Router>
     <ChatProvider>
        <Layout>
            <Routes>
            <Route path="/" element={<DocumentViewer />} />
            <Route path="/chat/:source" element={<Chat />} />
            </Routes>
        </Layout>
      </ChatProvider>
    </Router>
  );
};

export default App;