import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Send, User, Bot } from 'lucide-react';
import { useChat } from '../contexts/ChatContext';

const Chat: React.FC = () => {
  const { source } = useParams<{ source: string }>();
  const { chatHistories, addMessage } = useChat();
  const [input, setInput] = useState('');
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const messages = source ? chatHistories[source] || [] : [];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    setInput('');
  }, [source]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || !source) return;

    const userMessage = { role: 'user' as const, content: input };
    addMessage(source, userMessage);
    setInput('');
    setLoadingStates(prev => ({ ...prev, [source]: true }));

    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ source, message: input }),
      });
      const data = await response.json();
      const aiMessage = { role: 'ai' as const, content: data.response };
      addMessage(source, aiMessage);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, [source]: false }));
    }
  };

  if (!source) {
    return <div>No source selected</div>;
  }

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <div className="bg-white shadow-sm z-10 flex flex-row justify-between items-center">
        <h2 className="text-xl font-semibold p-4 text-gray-800">Chat with {source.replace('-', ' ')}</h2>
        <img src="/american-securities-logo.svg" alt="American Securities Logo" className="h-10 mr-4" />
      </div>
      <div className="flex-grow overflow-y-auto p-4 space-y-4">
        <div className="flex justify-start">
          <div className="flex items-start space-x-4 max-w-[75%]">
            <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center bg-gray-300">
              <Bot size={16} className="text-gray-600" />
            </div>
            <div className="p-3 rounded-lg shadow-sm bg-white text-gray-800">
              <p className="whitespace-pre-wrap text-sm">
                Hey! Please ask me any questions you want to know about {source.replace('-', ' ')}. I'll answer based on the interview transcripts I have available.
              </p>
            </div>
          </div>
        </div>
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`flex items-start space-x-4 max-w-[75%] ${message.role === 'user' ? 'flex-row-reverse space-x-reverse' : ''}`}>
              <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${message.role === 'user' ? 'bg-blue-500' : 'bg-gray-300'}`}>
                {message.role === 'user' ? <User size={16} className="text-white" /> : <Bot size={16} className="text-gray-600" />}
              </div>
              <div className={`p-3 rounded-lg shadow-sm ${message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'}`}>
                <p className="whitespace-pre-wrap text-sm">{message.content}</p>
              </div>
            </div>
          </div>
        ))}
        {loadingStates[source] && (
          <div className="flex justify-start">
            <div className="bg-gray-200 text-gray-600 p-3 rounded-lg shadow-sm animate-pulse">
              Thinking...
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="bg-white border-t border-gray-200 p-4">
        <form onSubmit={handleSubmit} className="flex items-center space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
          />
          <button 
            type="submit" 
            className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            disabled={loadingStates[source]}
          >
            <Send size={20} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;