import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FileText, Users } from 'lucide-react';

const Sidebar: React.FC = () => {
  const [sources, setSources] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    fetch('/api/sources')
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        if (!Array.isArray(data.sources)) throw new Error('Received sources is not an array');
        setSources(data.sources);
      })
      .catch(err => {
        console.error('Error fetching sources:', err);
        setError(err.message);
      });
  }, []);

  if (error) return <div className="text-red-500 p-4">Error: {error}</div>;

  return (
    <aside className="w-64 bg-gradient-to-b from-blue-50 to-white shadow-lg flex flex-col h-screen">
      <div className="p-4">
        <img src="/paradigm-logo-1.svg" alt="Paradigm Logo" className="h-16" />
      </div>
      <div className="flex flex-col h-full">
        <div className="flex-grow p-6">
          <nav>
            <Link 
              to="/" 
              className={`flex items-center text-lg mb-4 transition-colors duration-200 ease-in-out
                ${location.pathname === '/' ? 'text-blue-600 font-medium' : 'text-gray-600 hover:text-blue-500'}`}
            >
              <FileText className="mr-2 h-5 w-5" />
              Full Report
            </Link>
            <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wide mb-2">Sources</h3>
            <ul className="space-y-2">
              {sources.map(source => (
                <li key={source}>
                  <Link 
                    to={`/chat/${source}`} 
                    className={`flex items-center text-base transition-colors duration-200 ease-in-out
                      ${location.pathname === `/chat/${source}` ? 'text-blue-600 font-medium' : 'text-gray-600 hover:text-blue-500'}`}
                  >
                    <Users className="mr-2 h-4 w-4" />
                    {source.replace('-', ' ')}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="mt-auto p-4 border-t border-gray-200">
          <div className="flex items-center justify-center">
            <a href='https://every.to/' target='_blank' rel="noopener noreferrer">
              <img src="/every-stamp.png" alt="Every Logo" className="h-20" />
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
